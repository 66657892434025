<template>
    <div>
        <v-navigation-drawer v-model="drawer" app clipped>
            <v-list dense class="mt-0 pt-0">
                <div v-for="item in items" :key="item.text">
                    <v-list-item
                        v-if="item.type === 'item'"
                        :href="item.href"
                        :to="item.link"
                        :title="
                            item.hasOwnProperty('tooltip') ? item.tooltip : null
                        "
                    >
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="navItem">
                                {{ item.text }}<span class="menuLabel" v-if="item.hasOwnProperty('label')"
                                >{{ item.label.text}}
                            </span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-group
                        v-if="item.type === 'group'"
                        :prepend-icon="item.icon"
                        :ripple="false"
                    >
                        <template v-slot:activator>
                            <v-list-item-title class="navItem">{{
                                item.text
                            }}</v-list-item-title>
                        </template>
                        <v-list-item
                            v-for="subItem in item.items"
                            :key="subItem.text"
                            :href="subItem.href"
                            :to="subItem.link"
                            :ripple="false"
                        >
                            <v-list-item-icon class="ml-4">
                                <v-icon>{{ subItem.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="navItem">{{
                                subItem.text
                            }}<span class="menuLabel" v-if="subItem.hasOwnProperty('label')"
                                >{{ subItem.label.text}}
                            </span></v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </div>
                <v-divider />
                <v-list-item router to="/" @click="logOut">
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="navItem"
                        >Log out</v-list-item-title
                    >
                </v-list-item>
            </v-list>
            <div class="versionLabel">
                {{ version }}
            </div>
        </v-navigation-drawer>

        <v-app-bar app dark color="background" clipped-left>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            <router-link to="/">
                <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
                    <div class="d-flex align-center">
                        <v-img
                            alt="Joineer Name"
                            :src="require('../assets/joineer_logo.svg')"
                            class="shrink mr-2"
                            contain
                            transition="scale-transition"
                            width="120"
                        ></v-img>
                    </div>
                </v-toolbar-title>
            </router-link>
        </v-app-bar>
    </div>
</template>

<script>
import Configuration from "../services/configuration";
import { default as VERSION } from "@/../version.js"

export default {
    name: "Navbar",
    data: () => ({
        drawer: null,
        items: [
            {
                type: "item",
                icon: "mdi-calendar",
                text: "Calendar",
                link: "/calendar",
            },
            {
                type: "item",
                icon: "mdi-account-search",
                text: "Search Users",
                link: "/search",
            },
            {
                type: "item",
                icon: "mdi-domain",
                text: "Search Companies",
                link: "/company-search",
            },
            {
                type: "item",
                icon: "mdi-home-city-outline",
                text: "New Tutorial",
                link: "/tutorial",
            },
            {
                type: "item",
                icon: "mdi-table",
                text: "Import",
                link: "/import",
                tooltip: "Import a company via spreadsheet",
            },
            {
                type: "item",
                icon: "mdi-backup-restore",
                text: "Restore",
                link: "/restore",
                tooltip: "Restore a company via backup file",
            },

            {
                type: "group",
                icon: "mdi-cog",
                text: "Dev zone",
                items: [
                    {
                        type: "item",
                        icon: "mdi-page-next-outline",
                        text: "API Doc",
                        link: "/swagger",
                    },
                    {
                        type: "item",
                        icon: "mdi-delete-sweep",
                        text: "Clean up",
                        link: "/cleanup",
                    },
                    {
                        type: "item",
                        icon: "mdi-email",
                        text: "E-mail templates",
                        link: "/email",
                        tooltip: "Default e-mail templates",
                    },
                    {
                        type: "item",
                        icon: "sms",
                        text: "SMS templates",
                        link: "/sms",
                        tooltip: "SMS templates",
                    },

                ],
            },
        ],
        version: VERSION,
    }),
    methods: {
        logOut() {
            this.$store.dispatch("auth/logout");
        },
    },
};
</script>
<style scoped>
.navItem {
    color: rgb(24, 24, 24);
    font-weight: 400 !important;
}

.versionLabel {
    font-size: 0.8rem;
    color: rgb(157, 157, 157);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
</style>
