<template>
    <!-- Outlined card with a grey banner, icon and a title. Below is how you use it in other components:

<info-card title="Your title" icon="your-chosen-icon">
    <template v-slot:content>
         Your content
    </template>
</info-card>
-->

    <v-card flat outlined class="my-5">
        <div
            class="grey lighten-4 pa-2 d-flex justify-space-between align-center"
        >
            <div>
                <v-icon small left v-if="icon">{{ icon }}</v-icon
                >{{ title }}
            </div>
            <!-- button pushed to the right side of the toolbar -->
            <div><slot name="rightBtn"></slot></div>
        </div>
        <slot name="content"></slot>
    </v-card>
</template>

<script>
export default {
    props: ["icon", "title"]
};
</script>
