import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";

Vue.use(Vuex);

const initialState = {
    companies: null,
    notification: {
        show: false,
        mode: ""
    },
};

export const store = new Vuex.Store({
    modules: {
        auth
    },
    state: {
        ...initialState,
        notification: initialState.notification,
    },
    mutations: {
        HIDE_NOTIFICATION(state){
            state.notification.show = false
        },
        SET_COMPANIES(state, companyList) {
            state.companies = companyList;
        },
        SHOW_NOTIFICATION(state, mode){
            state.notification.show = true
            state.notification.mode = mode
        },
        RESET(state) {
            state = Object.assign(state, initialState, { notification: state.notification });
        },

    },
    actions: {
        setCompanyNames(context, companyList) {
            context.commit("SET_COMPANIES", companyList);
        },
        showNotification(context, mode){
            context.commit("SHOW_NOTIFICATION", mode);
        },
        hideNotification(context){
            context.commit("HIDE_NOTIFICATION");
        },
        reset(context) {
            context.commit("RESET");
            context.commit("auth/logout");
        },
    },
    strict: true
});
