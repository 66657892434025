import Configuration from "@/services/configuration";
import { store } from '@/store/store';
import { logout, signin, token} from "../api/auth.api";

class AuthService {
    login(user) {
        return signin(user).then( () => {
            return Promise.resolve();
            });
    }

    loginToken() {
        return token().then(response => {

            let roles = response.data.authorities.map(item => item.authority);
            if (!roles.includes('ROLE_ROOT')) {
                return Promise.reject('Not a root user')
            }

            if (response.data.accessToken) {
                localStorage.setItem('user', JSON.stringify(response.data));
                return Promise.resolve(response.data);
            }
            return Promise.reject('Access Token not found')
        });
    }


    logout() {
        localStorage.removeItem('user');
        return logout()
            .finally(() => {
                store.dispatch("reset");
                window.location.href = Configuration.value("loginUrl");
            });
    }
}

export default new AuthService();
