<template>
  <v-app :style="backgroundStyle">
    <Navbar v-if="!loginPages"
    />
    <Notification  />
    <v-main>
      <router-view class="fade-in"/>
    </v-main>
  </v-app>
</template>

<script>
import Notification from "./components/Notification";
import Navbar from "./components/Navbar";
export default {
  name: 'App',
    components: {Navbar,Notification},
    data: () => ({
    drawer: null,
    items: [
        { icon: 'mdi-calendar', text: 'Calendar', link: '/calendar'},
        { icon: 'mdi-account-search', text: 'Search Users', link: '/search'},
        { icon: 'mdi-home-city-outline', text: 'New Tutorial', link: '/tutorial'},
        { icon: 'mdi-database-import', text: 'Import', link: '/import'}
      ],
  }),
  computed:{
      backgroundStyle(){
        let color = this.loginPages ? '#EEEEEE' : 'white'
        return "background-color: " + color
      },
      loginPages(){
          return this.$route.name === 'Login' || this.$route.name === 'Login-success'

      },
  }
};
</script>
