import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import {store} from "./store/store"
import '@/components/global/register' // globally register reusables (InfoCard etc)
require('@/styles/styles.css')

const moment = require('moment') //set up date display

Vue.use(require('vue-moment'), {
    moment
})

import VTiptap from "@peepi/vuetify-tiptap";

Vue.use(VTiptap);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
