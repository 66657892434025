<template>
    <!--     global notification
   1)  show with custom icon and color: add a new key 'notifications object here. that will be the 'mode'. then trigger in component with the mode as an argument: $store.dispatch("showNotification", "templateCreated")
   2) simple text: $store.dispatch("showNotification", "Something happened")

   when 'notifications' object doesn't contain a key in the dispatch argument, it just shows the default styling with the text in the argument
 -->
    <v-snackbar
        app
        bottom
        height="70"
        :color="
            notifications.hasOwnProperty(mode)
                ? notifications[mode].color
                : mode && mode.color
                    ? mode.color
                    : 'primary'
        "
        v-model="showNotification"
        :timeout="2000"
        style="z-index: 10000"
    >
        <div class="px-3" style="font-size: 1rem">
            <div v-if="notifications.hasOwnProperty(mode)">
                <v-icon left>{{ notifications[mode].icon }}</v-icon>
                {{ notifications[mode].text }}
            </div>

            <div v-else-if="typeof mode === 'string'">
                {{ mode }}
            </div>
            <div v-else>
                <v-icon left>{{ mode.icon }}</v-icon>
                {{ mode.text }}
            </div>
        </div>
    </v-snackbar>
</template>

<script>
export default {
    name: "",
    components: {},
    data: () => ({
        notifications: {
            templateCreated: {
                color: "green",
                icon: "mdi-plus",
                text: "Template created",
            },
            templateEdited: {
                color: "primary",
                icon: "mdi-pencil",
                text: "Template edited",
            },
            templateDeleted: {
                color: "black",
                icon: "mdi-delete",
                text: "Template deleted",
            },
            emailSent:{
                color: "green",
                icon: "email",
                text: "E-mail sent to your @joineer e-mail (check spam) ",
            },
            smsSent:{
                color: "green",
                icon: "mdi-send",
                text: "SMS sent to your mobile number (if registered)",
            }
        },
    }),
    methods: {},
    computed: {
        showNotification: {
            get() {
                return this.$store.state.notification.show;
            },
            set() {
                this.$store.dispatch("hideNotification");
            },
        },
        mode() {
            return this.$store.state.notification.mode;
        },
    },
};
</script>

<style></style>
